(function() {
  var Faq, Home, checkPage, initBannerVideoSize, scaleBannerVideoSize, scaleVideoContainer, strip;

  window.App || (window.App = {});

  App.init = function(e) {
    if (checkPage('home')) {
      App.Home.init();
    }
  };

  $(document).ready((function(_this) {
    return function() {
      App.init();
    };
  })(this));

  $(document).on('change', '#textSize', function(e) {
    var scale;
    scale = $(this).val();
    $('#content.content-wrapper').attr('class', 'content-wrapper scale-' + scale);
  });

  $('.toggle-sub-menu').on('click', function(e) {
    var data, subMenu;
    data = $(this).data('toggle');
    subMenu = $('#' + data);
    if (subMenu.hasClass('visible')) {
      subMenu.removeClass('visible');
    } else {
      $('.sub-menu').removeClass('visible');
      subMenu.addClass('visible');
    }
  });

  $('.toggle-sub-menu-children').on('click', function(e) {
    var data, subMenu;
    data = $(this).data('toggle');
    subMenu = $('#' + data);
    if (subMenu.hasClass('visible')) {
      subMenu.removeClass('visible');
    } else {
      $('.sub-nav-children').removeClass('visible');
      subMenu.addClass('visible');
    }
  });

  $('#mobile-menu-trigger').on('click', function(e) {
    return $('.off-canvas-menu').toggleClass('visible');
  });

  $('.close-menu').on('click', function(e) {
    return $('.off-canvas-menu').removeClass('visible');
  });

  App.Faq = {
    logMessage: (function(_this) {
      return function(text) {
        console.log(text);
      };
    })(this),
    toggleCollapse: function(question) {
      var q;
      q = "#" + question.data('question');
      $(q).toggleClass('visible');
    }
  };

  Faq = App.Faq;

  $(document).on('click', '.faq-questions .question.collapsed header', function(e) {
    Faq.logMessage('Example event delegation! Yay');
    Faq.toggleCollapse($(this));
  });

  App.Home = {
    init: (function(_this) {
      return function(e) {
        Home.createSlider();
        Home.relayoutImages();
        document.getElementById("myVideo").play();
      };
    })(this),
    createSlider: function(e) {
      var homeSlider;
      homeSlider = document.querySelector('.hero-slider');
      App.Home.flkty = new Flickity(homeSlider, {
        cellAlign: 'left',
        autoPlay: 8000,
        pauseAutoPlayOnHover: false,
        contain: true,
        wrapAround: true,
        draggable: false,
        prevNextButtons: false,
        pageDots: false
      });
      App.Home.flkty.on('settle', function() {
        var els, flkty;
        $('#animatedData').removeClass('fadeIn').css({
          'opacity': 0
        });
        flkty = Home.flkty;
        els = flkty.getCellElements();
      });
    },
    initializeGrid: function(grid) {
      var iso;
      iso = new Isotope('.home-sections', {
        itemSelector: '.card',
        percentPosition: true,
        masonry: {
          columnWidth: '.grid-sizer',
          gutter: 40
        }
      });
      App.Home.addWaypoints();
    },
    relayoutImages: function() {
      var grid;
      grid = $('.home-sections');
      imagesLoaded(grid, function(instance) {
        return Home.initializeGrid(grid);
      });
    },
    fadeInCard: function(el) {
      $(el).addClass('fadeInUp');
    },
    addWaypoints: function() {
      var fadingCards, i;
      fadingCards = document.getElementsByClassName('card');
      i = 0;
      while (i < fadingCards.length) {
        new Waypoint({
          element: fadingCards[i],
          offset: '100%',
          handler: function() {
            App.Home.fadeInCard(this.element);
          }
        });
        i++;
      }
    }
  };

  Home = App.Home;

  $(document).on('click', '.home .slider-controls .play-controller', function(e) {
    var el, icon;
    el = $(this);
    icon = $(this).find('.icon');
    el.toggleClass('play');
    if (!el.hasClass('play')) {
      icon.addClass('fa-pause').removeClass('fa-play');
      App.Home.flkty.pausePlayer();
    } else {
      icon.removeClass('fa-pause').addClass('fa-play');
      App.Home.flkty.unpausePlayer();
    }
  });

  $(document).on('click', '.home .slider-controls .next', function(e) {
    var el;
    el = $('.slider-controls .play-controller');
    App.Home.flkty.next();
  });

  $(document).on('click', '.home .slider-controls .prev', function(e) {
    App.Home.flkty.previous();
  });

  checkPage = function(className) {
    var classList;
    classList = $('body').attr('class').split(' ');
    return classList.indexOf(className) > -1;
  };

  strip = function(html) {
    var tmp;
    tmp = document.implementation.createHTMLDocument('New').body;
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  scaleVideoContainer = function() {
    var height, unitHeight;
    height = $(window).height() + 5;
    unitHeight = parseInt(height) + 'px';
    $('.homepage-hero-module').css('height', unitHeight);
  };

  initBannerVideoSize = function(element) {
    $(element).each(function() {
      $(this).data('height', $(this).height());
      $(this).data('width', $(this).width());
    });
    scaleBannerVideoSize(element);
  };

  scaleBannerVideoSize = function(element) {
    var videoHeight, videoWidth, windowHeight, windowWidth;
    windowWidth = $(window).width();
    windowHeight = $(window).height() + 5;
    videoWidth = void 0;
    videoHeight = void 0;
    $(element).each(function() {
      var videoAspectRatio;
      videoAspectRatio = $(this).data('height') / $(this).data('width');
      $(this).width(windowWidth);
      if (windowWidth < 1000) {
        videoHeight = windowHeight;
        videoWidth = videoHeight / videoAspectRatio;
        $(this).css({
          'margin-top': 0,
          'margin-left': -(videoWidth - windowWidth) / 2 + 'px'
        });
        $(this).width(videoWidth).height(videoHeight);
      }
      $('.homepage-hero-module .video-container video').addClass('fadeIn animated');
    });
  };

  $(document).ready(function() {
    scaleVideoContainer();
    initBannerVideoSize('.video-container .poster img');
    initBannerVideoSize('.video-container .filter');
    initBannerVideoSize('.video-container video');
    $(window).on('resize', function() {
      scaleVideoContainer();
      scaleBannerVideoSize('.video-container .poster img');
      scaleBannerVideoSize('.video-container .filter');
      scaleBannerVideoSize('.video-container video');
    });
  });

}).call(this);
